<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Notification from "@/services/Notification";
import modalAddPushNotification from "@/components/modals/notification/modalAddPushNotification";
import Swal from "sweetalert2";

/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader, modalAddPushNotification },
  page: {
    title: "Notifications",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
        title: "Push Notifications",
        items: [
            {
                text: "Push Notifications",
                href: "/",
            },
        ],
        isBusy: false,
        tableData: [], 
        totalRows: 0,
        currentPage: 1,
        perPage: 25,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "setup_datetime",
        sortDesc: true,
        fields: [
            {
              key: "notification_group",
              label: "Notification Id",
            },
            {
                key: "title",
                label: "Title",
            },
            {
              key: "message",
              label: "Message",
            },
            {
              key: "recipient",
              label: "Recipient",
            },
            {
              key: "setup_datetime",
              label: "Scheduled",
            },
            {
              key: "initiated_datetime",
              label: "Initiated",
            },
            {
              key: "completed_datetime",
              label: "Completed",
            },
            "measure_time",
            {
              key: "success",
              label: "Success",
            },
            {
              key: "error",
              label: "Failed",
            },
            {
              key: "language",
              label: "Language",
            },
        ],
    };
  },
  middleware: "authentication",
  mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length;
  },
  created() {
     this.getNotifications()
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async getNotifications(){
        try {
            this.toggleBusy();
            const response = await Notification.getNotifications()
            this.tableData = response.data.notifications;
            this.totalRows = this.tableData.length
            this.toggleBusy();
        } catch (error) {
            this.error = error.response.data.error ? error.response.data.error : "";
            this.tableData = []
        }
    },
    modalAddPushNotification() {
        this.$bvModal.show("add_new_push_notification");
    },

    getTimestamp: function () {
      return Math.floor(new Date().getTime() / 1000)
    },

    deleteNotification(notification_group_id) {
          Swal.fire({
              title: "Are you sure?",
              text: "This Bulk Notification will be deleted !",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#34c38f",
              cancelButtonColor: "#f46a6a",
              confirmButtonText: "Yes, delete it!",
          }).then((result) => {
              if (result.value) {
                    Notification.deleteNotification(notification_group_id)
                    .then((response) => {
                        const res = response.data.success ? response.data.success : false;
                        const error = response.data.error ? response.data.error : 'Failed';
                        if(res){
                            this.getNotifications();
                            Swal.fire("Removed!", "Bulk Notification Removed !", "success");
                        }else{
                            Swal.fire("Fail!", error, "warning");
                        }
                    })
                    .catch(error => {
                        this.getNotifications();
                        Swal.fire("Fail!", error, "warning");
                    });
              }
          });
    },
    // difference between completed and initiated time of notificaion
      calculateMeasureTime(initiated, completed) {
        const initiatedTime = new Date(initiated).getTime();
        const completedTime = new Date(completed).getTime();
        
        if (!initiatedTime || !completedTime) return "N/A";

        const diffInSeconds = Math.floor((completedTime - initiatedTime) / 1000);
        const minutes = Math.floor(diffInSeconds / 60);
        const seconds = diffInSeconds % 60;

        if (minutes > 0) {
          return `${minutes}m ${seconds}s`; 
        } else {
          return `${seconds}s`; 
        }
      },

  },
    
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">

        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
                <div class="col-md-6">
                  <div class="mb-3">
                    <a  href="javascript:void(0);" class="btn btn-success waves-effect waves-light" @click="modalAddPushNotification">
                        <i class="mdi mdi-plus me-2"></i> 
                        Create New
                    </a>
                  </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                          Show&nbsp;
                          <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                          ></b-form-select
                          >&nbsp;entries
                      </label>
                      </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                      <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-end"
                      >
                      <label class="d-inline-flex align-items-center">
                          Search:
                          <b-form-input
                          v-model="filter"
                          type="search"
                          placeholder="Search..."
                          class="form-control form-control-sm ms-2"
                          ></b-form-input>
                      </label>
                      </div>
                  </div>
                  <!-- End search -->
                  </div>
                  <!-- Table -->
            <b-table
                :busy="isBusy"
                table-class="table table-centered datatable table-card-list"
                thead-tr-class="bg-transparent"
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                show-empty
                empty-text="No Data Found"
                @filtered="onFiltered"
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(notification_group)="data">

                <div v-if="data.item.notification_group">

                  <router-link :to="{ path: `/notifications/details?id=${data.item.notification_group}&type=group`}">
                    {{ data.item.notification_group }}
                  </router-link>
                </div>
                <div v-else>
                  <router-link :to="{ path: `/notifications/details?id=${data.item.notification_id}&type=single`}">
                    {{ data.item.notification_id }}
                  </router-link>
                </div>
              </template>

              <template v-slot:cell(language)="data">
                <div class="action-container">
                  <div class="setup-datetime" v-if="(getTimestamp() < data.item.set_up && data.item.set_up)">
                    {{ data.item.language }}
                  </div>

                  <span>{{ data.item.action_text }}</span>

                  <a
                      v-if="getTimestamp() < data.item.set_up && data.item.set_up"
                      href="javascript:void(0);"
                      class="delete-btn text-danger fs-3"
                      v-b-tooltip.hover
                      title="Delete"
                      @click="deleteNotification(data.item.notification_group)"
                  >
                    <i class="uil uil-trash-alt font-size-18"></i>
                  </a>

                  <div class="setup-datetime-else" v-else>
                    {{ data.item.language }}
                  </div>
                </div>
              </template>
            </b-table>
            <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-end">
                        <ul class="pagination pagination-rounded">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        
    <!-- MODALS --> 
    <modalAddPushNotification @refreshScreen="getNotifications" ></modalAddPushNotification>
    <!-- END  MODALS --> 

  </Layout>
</template>
<style scoped>
.table-card-list tbody tr:hover {
  background-color: #f9f9f9; /* Highlight row on hover */
  transition: background-color 0.2s ease; /* Smooth transition for hover effect */
}

/* Hide the delete button by default */
.action-container .delete-btn {
  display: none;
}

/* Show the delete button on row hover */
.table-card-list tbody tr:hover .action-container .delete-btn {
  display: inline-block;
}

/* Show setup_datetime-else when hover if delete button is not visible */
.table-card-list tbody tr:hover .setup-datetime {
  display: none;
}

/* Show the setup_datetime if the delete button is not visible */
.table-card-list tbody tr:hover .setup-datetime-else {
  display: inline-block;
}

/* Optionally, change the background color of the row when hovered */
.table-card-list tbody tr.row-hover {
  background-color: #f0f8ff; /* Light blue on hover */
}
.action-container {
  display: flex;
  justify-content: space-between; /* Move delete button to the right */
  align-items: center; /* Vertically center the items */
}

.delete-btn {
  margin-left: auto; /* Ensure delete button moves to the right */
}
</style>
